<template>
  <div class="geolocation-lookup-log">
    <iframe
      src="https://reporting.jumpfeed.com/geolocation_log.php?p=0908"
      title="Jumpfeed Geolocation Lookup Log"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "GeolocationLookupLog"
};
</script>

<style scoped lang="scss">
iframe {
  width: calc(100% + (2 * #{$space-lg}));
  min-height: calc(100vh - 56px);
  height: min-content;
  margin: -#{$space-xl} -#{$space-lg};
  border: 0;
}
</style>
